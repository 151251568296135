<template>
  <div class="additional-option-wrapper"
       :class="{'always-open': isCollapseVisible}">
    <div class="additional-option">
      <div class="additional-option__icon">
        <icon-settins/>
      </div>
      <div class="additional-option__name">
        <span>Протяженность задействованной сети</span>
      </div>
      <div class="additional-option__buttons">
        <button
          v-if="!Object.values(this.networkSpread).some(item => item) && isActionsVisible"
          class="additional-option__add"
          type="button"
          @click="showModal()">
          <icon-plus/>
        </button>
        <button
          @click="isCollapseVisible = !isCollapseVisible"
          class="additional-option__toggle"
          :class="{'rotate': isCollapseVisible}">
          <icon-arrow-double-up/>
        </button>
      </div>
    </div>
    <div class="additional-options-list">
      <div v-if="Object.values(this.networkSpread).some(item => item)"
           class="additional-options-list__inner">
        <div @click="showModal()"
             class="added-item added-item--flex">
          <div class="added-item__info">
            <div class="added-item__name">Протяженность: {{ networkSpread.spread }}м</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconSettins from "@/components/Icons/IconSettins";
import IconPlus from "@/components/Icons/IconPlus";
import IconArrowDoubleUp from "@/components/Icons/IconArrowDoubleUp";
import NetworkSpreadForm from "@/components/ServiceDesk/Tasks/TaskComponents/NetworkSpread/NetworkSpreadForm"
import {modalAdaptiveWidth} from "@/utils/helpers";

export default {
  name: "NetworkSpreadItem",
  components: {IconArrowDoubleUp, IconPlus, IconSettins},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    networkSpread: {
      type: Object,
      required: true
    },
    isActionsVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isCollapseVisible: false
    }
  },
  methods: {
    showModal() {
      this.$modal.show(
        NetworkSpreadForm,
        {
          'network-spread': this.networkSpread,
          'task-id': this.taskId,
          'is-actions-visible': this.isActionsVisible
        },
        {
          name: 'network-spread-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(600)
        }
      )
    }
  }
}
</script>

<style scoped>
.added-item {
  cursor: pointer;
}
</style>
