<template>
  <div class="page-wrapper">
    <base-panel
      title="Подразделения"
      :is-add-button-visible="true"
      add-button-name="Добавить подразделение"
      @add-button-clicked="showPopup()"/>

    <base-functional-line
      search-placeholder="Искать по наименованию"
      @search-change="event => this.query = event.target.value"
    />

    <departments-list
      v-if="departmentsList.length"
      :departmentsList="departmentsList"
      @edit-department="showPopup"
      @open-department-modal="openDepartmentModal"/>

    <base-content-loaders v-else-if="loading" />

    <base-no-data v-if="!loading && !departmentsList.length"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      commit="departments/SET_PAGE"
      action="departments/getDepartments"/>
  </div>
</template>

<script>
import DepartmentsList from "@/components/ServiceDesk/admin/Departments/DepartmentsList.vue";
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import {mapState} from "vuex";
import DepartmentsForm from "@/components/ServiceDesk/admin/Departments/DepartmentsForm.vue";
import {showAlert, showToast} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";
import sideModalControls from "@/mixins/sideModalControls";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import BaseNoData from "@/components/BaseNoData.vue";
export default {
  name: "DepartmentsListPage",
  components: {BaseNoData, BaseFunctionalLine, BasePaginate, BaseContentLoaders, BasePanel, DepartmentsList},
  mixins: [sideModalControls],
  computed: {
    ...mapState({
      departmentsList: state => state.departments.departments,
      meta: state => state.departments.meta,
      loading: state => state.departments.loading
    }),
    query: {
      get() {
        return this.$store.state.departments.query
      },
      set(value) {
        this.$store.commit('departments/SET_QUERY', value);
      }
    }
  },
  watch: {
    query: function () {
      this.$store.commit('departments/SET_PAGE', 1);
      this.$store.dispatch('departments/getDepartments');
    }
  },
  mounted() {
    this.$store.commit('departments/SET_PAGE', 1);
    this.$store.dispatch('departments/getDepartments');
  },
  beforeUnmount() {
    this.$store.dispatch('baseSideModal/closeModal');
  },
  methods: {
    showPopup(department = {}) {
      this.$modal.show(
        DepartmentsForm,
        {
          department: department,
          createDepartment: this.createDepartment,
          updateDepartment: this.updateDepartment
        },
        {
          name: 'department-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        }
      )
    },
    createDepartment(payload) {
      this.$store.dispatch('departments/createDepartment', payload)
        .then(() => {
          showToast('Подразделение создано', 'success');
          this.$store.dispatch('departments/getDepartments');
          this.$modal.hide('department-form');
        })
        .catch(error => showAlert('Ошибка', error, 'error'))
    },
    updateDepartment(departmentId, payload) {
      this.$store.dispatch('departments/updateDepartment', {
        department_id: departmentId,
        payload: payload
      })
        .then(() => {
          showToast('Подразделение обновлено', 'success');
          this.$store.dispatch('departments/getDepartments');
          this.$modal.hide('department-form');
        })
        .catch(error => showAlert('Ошибка', error, 'error'))
    }
  }
}
</script>

<style scoped>

</style>
