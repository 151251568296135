<template>
  <div class="file-input-container">
    <div
      v-if="!file"
      class="file-input__dropzone-container"
      :class="{'dragover': isDragging}"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        id="fileInput"
        ref="file"
        type="file"
        :accept="acceptedFiles"
        class="file-input__hidden-input"
        @change.stop.prevent="handleFileUpload"
      >

      <label
        for="fileInput"
        class="file-input__label">
        <div class="file-input__text">
          Переместите файл в данную область <br> или выберите файл
        </div>
        <div class="button button--mode-outline">Выбрать файл</div>
      </label>

      <div class="file-input__error-text">{{textError}}</div>
    </div>

    <div
      v-else
      class="file-preview">
      <div class="file-preview__body">
        <div class="file-preview__icon">
          <icon-document-text-colored/>
        </div>
        <div class="file-preview__info">
          <div class="file-preview__name">{{ fileCaption.name }}</div>
          <div class="file-preview__ext">{{ fileCaption.extension }}, {{ fileSizeInKb }} kb</div>
        </div>
      </div>
      <div
        @click="file = null"
        class="file-preview__delete">
        <icon-trash/>
      </div>
    </div>
  </div>
</template>

<script>
import IconDocumentTextColored from "@/components/Icons/IconDocumentTextColored.vue";
import IconTrash from "@/components/Icons/IconTrash.vue";

export default {
  name: "BaseDragAndDropFileInput",
  components: {IconTrash, IconDocumentTextColored},
  props: {
    error: {
      type: Boolean,
      default: false
    },
    acceptedFiles: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      file: null,
      textError: ''
    }
  },
  computed: {
    fileSizeInKb() {
      const fileSizeInKb = this.file.size / 1024;
      return fileSizeInKb.toFixed(2);
    },
    fileCaption() {
      const [name, extension] = this.file.name.split('.');
      return {name, extension};
    }
  },
  methods: {
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.isDragging = false;

      const [file] = e.dataTransfer.files;

      if (this.isAcceptedFileType(file)) {
        this.textError = '';
        this.file = file;

        this.$emit('change', file);
      } else {
        this.textError = 'Файл не соответствует допустимым форматам'
      }
    },
    isAcceptedFileType(file) {
      const acceptedTypes = this.acceptedFiles.split(',').map(type => type.trim());
      const fileExtension = `.${file.name.split('.').pop()}`;

      return acceptedTypes.includes(fileExtension);
    },
    handleFileUpload() {
      const [file] = this.$refs.file.files;
      this.file = file;

      this.$emit('change', file);
    }
  }
}
</script>

<style scoped>
.file-input-container {
  width: 100%;
}

.file-input__error-text {
  font-size: 12px;
  color: var(--bg-pink);
  text-align: center;
}
</style>
