import { showToast } from "@/utils/notification";

export const getError = error => {
    const errorMessage = "ошибка API, попробуйте ещё раз.";

    if (error.name === "Fetch User") {
        return error.message;
    }

    if (!error.response) {
        return errorMessage;
    }

    if (error.response.data && error.response.data.errors) {
        let error_string  = '';
        error.response.data.errors.forEach(function(item) {
            error_string += item.detail + "\n";
        });
        return error_string;
    }

    return errorMessage;
};

export const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) map.set(key, [item])
        else collection.push(item)
    })
    return map;
}

export const isNumber = event => {
    event = (event) ? event : window.event;
    let charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
        event.preventDefault();
    } else {
        return true;
    }
}

export const modalAdaptiveWidth = desktopWidth => {
    const windowInnerWidth = window.innerWidth
    if (windowInnerWidth > 767) return desktopWidth
    else return '100%'
}

export const disableDatesBeforeToday = date => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return date < today
}

export const notBeforeStartDate = (date, start) => {
    let startDate = new Date(start);
    startDate.setDate(startDate.getDate() - 1);
    return date < startDate;
}

export function handleDateChange(dates, key, context) {
    if (dates.length === 2) {
        const [startDate, endDate] = dates;

        const differenceInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

        if (differenceInDays > 31) {
            showToast("Максимальный диапазон выбора - 31 день.", 'warning');
            context[key] = [startDate, new Date(startDate.getTime() + (31 * 24 * 60 * 60 * 1000))];
        } else {
            context[key] = dates;
        }
    } else {
        context[key] = dates;
    }
}

export const getDatesRangeCaption = (start, end) => {
    if (start.date() === end.date()) {
        return `${start.format('DD.MM.YY, HH:mm')} - ${end.format('HH:mm')}`
    } else return `${start.format('DD.MM.YY, HH:mm')} - ${end.format('DD.MM.YY, HH:mm')}`
}

export const getCssVariableColor = name => {
    const bodyStyles = window.getComputedStyle(document.body);
    return bodyStyles.getPropertyValue(name);
}

export const deleteObjectNullProperties = object => {
    Object.keys(object).forEach(key => {
        if (object[key] === null) {
            delete object[key];
        }
    });

    return object;
}

export const roundedNumber = (value, precision) => {
    return typeof value === 'number' ? value.toFixed(precision) : null;
}

export const isValueOutOfProfile = (value, profileItem) => {
    if (value && profileItem) {
        const {min, max} = profileItem;
        return value > max || value < min;
    } return false;
}

export const isValueLessThenMin = (value, profileItem) => {
    if (value && profileItem) {
        const {min} = profileItem;
        return value < min;
    } return false
}

export const parameterTimeStatus = (parameterValue = {}) => {
    if (parameterValue === {}) return 'error'

    const currentDate = new Date();
    const dayStart = new Date().setHours(0, 0, 0, 0);
    const parameterValueDate = new Date(parameterValue?.readingAt);

    if ((currentDate - parameterValueDate) <= 300000) {
        return 'success'
    } else if (dayStart < parameterValueDate && (currentDate - parameterValueDate) >= 300000) {
        return 'warn'
    } else if (dayStart > parameterValueDate) {
        return 'error'
    }
}
