import NotFoundPage from "@/views/NotFoundPage.vue";
import auth from "@/middleware/auth";
import DashboardPage from "@/views/DashboardPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import guest from "@/middleware/guest";
import AccessDeniedPage from "@/views/AccessDeniedPage.vue";
import TelemetryPage from "@/views/Telemetry/TelemetryPage.vue";
import objectMonitoring from "@/middleware/objectMonitoring";
import TelemetryListsPage from "@/views/Telemetry/TelemetryListsPage.vue";
import ScadaPage from "@/views/Telemetry/ScadaPage.vue";
import checkPermission from "@/middleware/checkPermission";
import ArchiveJournal from "@/views/Telemetry/ArchiveJournalPage.vue";
import NetworkEquipmentTypesListPage from "@/views/Telemetry/admin/NetworkEquipmentTypesListPage.vue";
import LogbookPage from "@/views/Telemetry/LogbookPage.vue";
import TransportTrackingPage from "@/views/Transport/TransportTrackingPage.vue";
import transportTracking from "@/middleware/transportTracking";
import TransportCatalogPage from "@/views/Transport/admin/TransportCatalogPage.vue";
import TransportDataServersListPage from "@/views/Transport/admin/TransportDataServersListPage.vue";
import admin from "@/middleware/admin";
import IssuesDashboardPage from "@/views/ServiceDesk/IssuesDashboardPage.vue";
import IssuesListPage from "@/views/ServiceDesk/IssuesListPage.vue";
import TasksListPage from "@/views/ServiceDesk/TasksListPage.vue";
import AppealsDashboardPage from "@/views/CallCenter/AppealsDashboardPage.vue";
import AppealsList from "@/views/CallCenter/AppealsListPage.vue";
import EmergencyElementsPage from "@/views/ServiceDesk/EmergencyElementsPage.vue";
import AccidentReportPage from "@/views/ServiceDesk/AccidentReportPage.vue";
import DailyReportPage from "@/views/ServiceDesk/DailyReportPage.vue";
import IssuesCountReportPage from "@/views/ServiceDesk/IssuesCountReportPage.vue";
import FuelAndLubricantsReportPage from "@/views/ServiceDesk/FuelAndLubricantsReportPage.vue";
import WaterFlowReportPage from "@/views/ServiceDesk/WaterFlowReportPage.vue";
import TransportLoadReportPage from "@/views/ServiceDesk/TransportLoadReportPage.vue";
import TransportOrdersListPage from "@/views/ServiceDesk/TransportOrdersListPage.vue";
import BrigadesWorkShiftPage from "@/views/ServiceDesk/BrigadesWorkShiftPage.vue";
import ResourceDashboardPage from "@/views/ServiceDesk/ResourceDashboardPage.vue";
import DepartmentsListPage from "@/views/ServiceDesk/admin/DepartmentsListPage.vue";
import ResourcesListPage from "@/views/ServiceDesk/admin/ResourcesListPage.vue";
import TaskTypesListPage from "@/views/ServiceDesk/admin/TaskTypesListPage.vue";
import WorksListPage from "@/views/ServiceDesk/admin/WorksListPage.vue";
import MechanicalEquipmentsListPage from "@/views/ServiceDesk/admin/MechanicalEquipmentsListPage.vue";
import CategoriesListPage from "@/views/ServiceDesk/admin/CategoriesListPage.vue";
import ConsumablesListPage from "@/views/ServiceDesk/admin/ConsumablesListPage.vue";
import DefectsListPage from "@/views/ServiceDesk/admin/DefectsListPage.vue";
import OperationalSchemePage from "@/views/ServiceDesk/OperationalSchemePage.vue";
import ShutdownNotificationsPage from "@/views/ServiceDesk/ShutdownNotificationsPage.vue";
import BillingBuildings from "@/views/Billing/BillingBuildingsPage.vue";
import billing from "@/middleware/billing";
import BillingClients from "@/views/Billing/BillingClientsPage.vue";
import StandardBuildingPage from "@/views/Billing/StandardBuildingPage.vue";
import BalancePage from "@/views/Billing/BalancePage.vue";
import ZonesListPage from "@/views/Billing/admin/ZonesListPage.vue";
import BalanceGroup from "@/views/Billing/admin/BalanceGroupPage.vue";
import UsersListPage from "@/views/admin/UsersListPage.vue";
import RolesLayout from "@/views/admin/RolesPage.vue";
import NetworkObjectsListPage from "@/views/Telemetry/admin/NetworkObjectsListPage.vue";
import LayersCatalogPage from "@/views/admin/LayersCatalogPage.vue";
import EngineeringNetworksListPage from "@/views/EngineeringNetwork/EngineeringNetworksListPage.vue";
import NetworkObjectTypesListPage from "@/views/Telemetry/admin/NetworkObjectTypesListPage.vue";
import TechnicalServiceWorksPage from "@/views/Telemetry/admin/TechnicalServiceWorksPage.vue";
import EnergyConsumptionObjectsListPage from "@/views/EnergyConsumption/admin/EnergyConsumptionObjectsListPage.vue";
import EnergyConsumptionLogbookPage from "@/views/EnergyConsumption/EnergyConsumptionLogbookPage.vue";
import StructuralUnitsListPage from "@/views/admin/StructuralUnitsListPage.vue";
import SemanticProvidersListPage from "@/views/EngineeringNetwork/SemanticProvidersListPage.vue";
import ResourcesLoadPage from "@/views/ServiceDesk/ResourcesLoadPage.vue";
import EngineeringNetworksLengthPage from "@/views/EngineeringNetwork/EngineeringNetworksLengthPage.vue";
import TechnicalInspectionNetworkObjectTypes
    from "@/views/TechnicalInspection/NetworkObjectTypes/NetworkObjectTypesListPage.vue";
import NetworkObjectTypeConfigPage
    from "@/views/TechnicalInspection/NetworkObjectTypes/NetworkObjectTypeConfigPage.vue";
import DictionariesListPage from "@/views/TechnicalInspection/Dictionary/DictionariesListPage.vue";
import DictionaryPage from "@/views/TechnicalInspection/Dictionary/DictionaryPage.vue";
import TechnicalInspectionTasksListPage from "@/views/TechnicalInspection/TechnicalInspectionTasksListPage.vue";
import TechnicalInspectionListObject from "@/views/TechnicalInspection/TechnicalInspectionObjectsListPage.vue";
import ResourcesManagePage from '@/views/ServiceDesk/ResourcesListPage.vue'
import TechnicalInspectionObjectsStatisticPage
    from "@/views/TechnicalInspection/Statistic/TechnicalInspectionObjectsStatisticPage.vue";
import TechnicalInspectionResourcesStatisticPage
    from "@/views/TechnicalInspection/Statistic/TechnicalInspectionResourcesStatisticPage.vue";

export default [
    {
        path: '/',
        name: 'DashboardPage',
        component: DashboardPage,
        meta: {middleware: [auth], title: 'Главная'}
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
        meta: {middleware: [guest], title: 'Авторизация', layout: 'Login'}
    },
    {
        path: '/access-denied',
        name: 'AccessDenied',
        component: AccessDeniedPage,
        meta: {middleware: [auth], title: 'Доступ запрещен'}
    },
    {
        path: '/telemetry',
        name: 'Telemetry',
        component: TelemetryPage,
        meta: {middleware: [auth, objectMonitoring], title: 'Телеметрия'}
    },
    {
        path: '/telemetry-lists',
        name: 'TelemetryLists',
        component: TelemetryListsPage,
        meta: {middleware: [auth, objectMonitoring], title: 'Телеметрия'}
    },
    {
        path: '/scada',
        name: 'SCADA',
        component: ScadaPage,
        meta: {
            middleware: [auth, checkPermission],
            title: 'SCADA - система мониторинга параметров',
            permission: 'object-monitoring.scada'
        }
    },
    {
        path: '/telemetry-archive',
        name: 'ArchiveJournal',
        component: ArchiveJournal,
        meta: {middleware: [auth, objectMonitoring], title: 'Архивный журнал'}
    },
    {
        path: '/network-equipment-types',
        name: 'NetworkEquipmentTypes',
        component: NetworkEquipmentTypesListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'network-engineering.settings',
            title: 'Типы сетевого оборудования'
        }
    },
    {
        path: '/logbook',
        name: 'Logbook',
        component: LogbookPage,
        meta: {middleware: [auth, objectMonitoring], title: 'Журнал ручного ввода'}
    },
    {
        path: '/tracking',
        name: 'Tracking',
        component: TransportTrackingPage,
        meta: {middleware: [auth, transportTracking], title: 'Трекинг транспорта'}
    },
    {
        path: '/admin-autos',
        name: 'Autos',
        component: TransportCatalogPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'transport.management',
            title: 'Автопарк'
        }
    },
    {
        path: '/transport-data-servers',
        name: 'TransportDataServers',
        component: TransportDataServersListPage,
        meta: {middleware: [auth, admin], title: 'Сервера данных транспорта'}
    },
    {
        path: '/issues-dashboard',
        name: 'IssuesDashboard',
        component: IssuesDashboardPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.issues',
            title: 'Статистика заявок'
        }
    },
    {
        path: '/issues-list',
        name: 'IssuesList',
        component: IssuesListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.issues',
            title: 'Диспетчерская'
        }
    },
    {
        path: '/tasks',
        name: 'TasksListPage',
        component: TasksListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.issues',
            title: 'Задания'
        }
    },
    {
        path: '/appeals-dashboard',
        name: 'AppealsDashboard',
        component: AppealsDashboardPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.appeals',
            title: 'Статистика обращений'
        }
    },
    {
        path: '/appeals-list',
        name: 'AppealsList',
        component: AppealsList,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.appeals',
            title: 'Обращения'
        }
    },
    {
        path: '/emergency-elements-reports',
        name: 'EmergencyElementsReports',
        component: EmergencyElementsPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.reports',
            title: 'Аварийные элементы - отчёты'
        }
    },
    {
        path: '/accident-reports',
        name: 'AccidentReports',
        component: AccidentReportPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.reports',
            title: 'Статистика по аварийности - отчёты'
        }
    },
    {
        path: '/daily-reports',
        name: 'dailyReportPage',
        component: DailyReportPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.reports',
            title: 'Суточный отчет ЕДС - отчёты'
        }
    },
    {
        path: '/issues-count-reports',
        name: 'IssuesCountReportPage',
        component: IssuesCountReportPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.reports',
            title: 'Показатели в динамике - отчёты'
        }
    },
    {
        path: '/fuel-and-lubricants-reports',
        name: 'FuelAndLubricantsReports',
        component: FuelAndLubricantsReportPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.reports',
            title: 'Расход ГСМ - отчёты'
        }
    },
    {
        path: '/water-flow-reports',
        name: 'WaterFlowReports',
        component: WaterFlowReportPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.reports',
            title: 'Расходы и потери воды - отчёты'
        }
    },
    {
        path: '/transport-load-report',
        name: 'TransportLoadReport',
        component: TransportLoadReportPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.reports',
            title: 'Загруженность транспорта - отчёты'
        }
    },
    {
        path: '/transport-orders',
        name: 'TransportOrders',
        component: TransportOrdersListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.transport-orders',
            title: 'Заказы транспорта'
        }
    },
    {
        path: '/brigades-work-shift',
        name: 'BrigadesWorkShift',
        component: BrigadesWorkShiftPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.resource_management',
            title: 'Расписание работы бригад'
        }
    },
    {
        path: '/resource-statistic/:id',
        name: 'ResourceStatistic',
        component: ResourceDashboardPage,
        meta: {middleware: [auth], title: 'Статистика бригады'}
    },
    {
        path: '/departments',
        name: 'Departments',
        component: DepartmentsListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.settings.departments',
            title: 'Справочник подразделений'
        }
    },
    {
        path: '/resources',
        name: 'Resources',
        component: ResourcesListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.settings.resources',
            title: 'Справочник ресурсов'
        }
    },
    {
        path: '/task-types',
        name: 'TaskTypes',
        component: TaskTypesListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.settings.task-types',
            title: 'Справочник типов заданий'
        }
    },
    {
        path: '/works',
        name: 'Works',
        component: WorksListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.settings.works',
            title: 'Справочник работ'
        }
    },
    {
        path: '/mechanical-equipments',
        name: 'MechanicalEquipments',
        component: MechanicalEquipmentsListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.settings.equipments',
            title: 'Справочник средств малой механизации'
        }
    },
    {
        path: '/service-desk-categories',
        name: 'ServiceDeskCategories',
        component: CategoriesListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.settings.categories',
            title: 'Справочник категорий'
        }
    },
    {
        path: '/consumables-catalog',
        name: 'ConsumablesCatalog',
        component: ConsumablesListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.settings.materials',
            title: 'Справочник расходных материалов'
        }
    },
    {
        path: '/defects-catalog',
        name: 'DefectsCatalog',
        component: DefectsListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.settings.defects',
            title: 'Справочник дефектов'
        }
    },
    {
        path: '/operational-scheme',
        name: 'OperationScheme',
        component: OperationalSchemePage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.issues',
            title: 'Оперативная схема'
        }
    },
    {
        path: '/shutdown-notifications',
        name: 'ShutdownNotifications',
        component: ShutdownNotificationsPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.shutdown_notification',
            title: 'Оповещения об отключениях'
        }
    },
    {
        path: '/billing-buildings',
        name: 'BillingBuildings',
        component: BillingBuildings,
        meta: {middleware: [auth, billing], title: 'Фильтр по объектам'}
    },
    {
        path: '/billing-clients',
        name: 'BillingClients',
        component: BillingClients,
        meta: {middleware: [auth, billing], title: 'Фильтр по абонентам'}
    },
    {
        path: '/standard-building',
        name: 'StandardBuilding',
        component: StandardBuildingPage,
        meta: {middleware: [auth, billing], title: 'Эталонный объект'}
    },
    {
        path: '/water-balance',
        name: 'WaterBalance',
        component: BalancePage,
        meta: {middleware: [auth, billing], title: 'Баланс водоснабжения'}
    },
    {
        path: '/drain-balance',
        name: 'DrainBalance',
        component: BalancePage,
        meta: {middleware: [auth, billing], title: 'Баланс водоотведения'}
    },
    {
        path: '/admin-zones',
        name: 'Zones',
        component: ZonesListPage,
        meta: {middleware: [auth, admin], title: 'Зоны водоснабжения'}
    },
    {
        path: '/admin-balance-groups',
        name: 'BalanceGroup',
        component: BalanceGroup,
        meta: {middleware: [auth, admin], title: 'Балансовые группы'}
    },
    {
        path: '/admin-users',
        name: 'Users',
        component: UsersListPage,
        meta: {middleware: [auth, admin], title: 'Пользователи'}
    },
    {
        path: '/admin-roles',
        name: 'Roles',
        component: RolesLayout,
        meta: {middleware: [auth, admin], title: 'Роли'}
    },
    {
        path: '/network-objects-catalog',
        name: 'NetworkObjects',
        component: NetworkObjectsListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'network-engineering.settings',
            title: 'Объекты сети'
        }
    },
    {
        path: '/admin-layers',
        name: 'Layers',
        component: LayersCatalogPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'network-engineering.settings',
            title: 'Слои'
        }
    },
    {
        path: '/engineering-networks',
        name: 'EngineeringNetworks',
        component: EngineeringNetworksListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'network-engineering.settings',
            title: 'Справочник инженерных сетей'
        }
    },
    {
        path: '/network-object-types',
        name: 'NetworkObjectTypes',
        component: NetworkObjectTypesListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'network-engineering.settings',
            title: 'Типы объектов сети'
        }
    },
    {
        path: '/technical-service-works',
        name: 'TechnicalServiceWorks',
        component: TechnicalServiceWorksPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'network-engineering.settings',
            title: 'Виды работ технического обслуживания'
        }
    },
    {
        path: '/energy-consumption-object-catalog',
        name: 'EnergyConsumptionObjectCatalog',
        component: EnergyConsumptionObjectsListPage,
        meta: {
            middleware: [auth, checkPermission],
            title: 'Каталог объектов энергопотребления',
            permission: 'energy-consumption'
        }
    },
    {
        path: '/energy-consumption-logbook',
        name: 'EnergyConsumptionLogbookPage',
        component: EnergyConsumptionLogbookPage,
        meta: {
            middleware: [auth, checkPermission],
            title: 'Журнал энергопотребления',
            permission: 'energy-consumption'
        }
    },
    {
        path: '/structural-units',
        name: 'StructuralUnits',
        component: StructuralUnitsListPage,
        meta: {middleware: [auth, admin], title: 'Структурные подразделения'}
    },
    {
        path: '/semantic-providers-catalog',
        name: 'SemanticProvidersCatalog',
        component: SemanticProvidersListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'network-engineering.settings',
            title: 'Провайдеры семантических данных'
        }
    },
    {
        path: '/resource-rating',
        name: 'ResourceRating',
        component: ResourceDashboardPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.resource_management',
            title: 'Статистика по ресурсам'
        }
    },
    {
        path: '/resources-load',
        name: 'ResourcesLoad',
        component: ResourcesLoadPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.resource_management',
            title: 'Загруженность ресурсов'
        }
    },
    {
        path: '/networks-length',
        name: 'NetworksLength',
        component: EngineeringNetworksLengthPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'network-engineering.length',
            title: 'Протяженность сетей'
        }
    },
    {
        path: '/technical-inspection-network-object-types',
        name: 'TechnicalInspectionNetworkObjectTypes',
        component: TechnicalInspectionNetworkObjectTypes,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'technical-inspection.settings.types',
            title: 'Конфигурация типов объектов'
        }
    },
    {
        path: '/technical-inspection-network-object-types/:id',
        name: 'TechnicalInspectionNetworkObjectType',
        component: NetworkObjectTypeConfigPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'technical-inspection.settings.types',
            title: 'Конфигурация типа объекта'
        }
    },
    {
        path: '/technical-inspection-dictionaries',
        name: 'TechnicalInspectionDictionaries',
        component: DictionariesListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'technical-inspection.settings.dictionaries',
            title: 'Словари'
        }
    },
    {
        path: '/technical-inspection-dictionaries/:id',
        name: 'TechnicalInspectionDictionary',
        component: DictionaryPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'technical-inspection.settings.dictionaries',
            title: 'Словарь'
        }
    },
    {
        path: '/technical-inspection-tasks',
        name: 'TechnicalInspectionTasks',
        component: TechnicalInspectionTasksListPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'technical-inspection',
            title: 'Задания на обход'
        }
    },
    {
        path: '/technical-inspection-network-objects',
        name: 'TechnicalInspectionNetworkObjects',
        component: TechnicalInspectionListObject,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'technical-inspection',
            title: 'Объекты обхода'
        }
    },
    {
        path: '/resources-list',
        name: 'ResourcesList',
        component: ResourcesManagePage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'service-desk.resource_management',
            title: 'Ресурсы'
        }
    },
    {
        path: '/technical-inspection-resources-statistic',
        name: 'TechnicalInspectionResourcesStatistic',
        component: TechnicalInspectionResourcesStatisticPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'technical-inspection',
            title: 'Статистика по ресурсам'
        }
    },
    {
        path: '/technical-inspection-objects-statistic',
        name: 'TechnicalInspectionObjectsStatistic',
        component: TechnicalInspectionObjectsStatisticPage,
        meta: {
            middleware: [auth, checkPermission],
            permission: 'technical-inspection',
            title: 'Статистика по объектам'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFoundPage,
        meta: {middleware: [auth], title: '404'}
    }
]
