import * as API from './API';
import {TechnicalInspectionListObject} from "@/models/TechnicalInspection/TechnicalInspectionListObject";
import {getError} from "@/utils/helpers";
import {TechnicalInspectionObject} from "@/models/TechnicalInspection/TechnicalInspectionObject";
import TaskViolation from "@/models/TechnicalInspection/TaskViolation";

export default {
    fetchTechnicalInspectionObject(objectId) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/objects/${objectId}`)
                .then(response => {
                    const {id, attributes} = response.data.data;
                    resolve(new TechnicalInspectionObject({id, ...attributes}))
                })
                .catch(error => reject(getError(error)))
        })
    },
    fetchTechnicalInspectionObjectsList(params, page) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/objects?page=${page}`, {params})
                .then(response => {
                    resolve({
                        objectsList: response.data.data
                            .map(item => new TechnicalInspectionListObject({id: item.id, ...item.attributes})),
                        meta: response.data.meta
                    })
                })
                .catch(error => reject(getError(error)))
        })
    },
    fetchTechnicalInspectionObjectViolationsList(objectId, params) {
        return new Promise((resolve, reject) => {
            API.apiClient.get(`/technical-inspection/objects/${objectId}/violations`, {params})
                .then(response => {
                    resolve(response.data.data.map(item => new TaskViolation({id: item.id, ...item.attributes})))
                })
                .catch(error => reject(getError(error)))
        })
    }
}
