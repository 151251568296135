<template>
  <base-popup
    :title="popupTitle"
    :name="popupName"
  >
    <template #body>
      <div class="form-item">
        <div class="form-item__name">План. дата начала</div>
        <div class="form-item__value form-item__value--column">
          <base-date-picker
            v-model="payload.planned_start_date"
            placeholder="Выберите дату и время"
            enable-time-picker
            :format="DATE_TIME_FORMAT"
            value-format="yyyy-MM-dd HH:mm"
            :flow="['calendar', 'time']"
          />
          <div class="form-item__value-tags">
            <div
              class="form-item__value-tag"
              v-for="tag in dateTags"
              :key="tag.value"
              @click="payload.planned_start_date = moment().add(tag.value, 'hours').format('YYYY-MM-DD HH:mm')"
            >{{ tag.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Факт. дата начала</div>
        <div class="form-item__value form-item__value--column">
          <base-date-picker
            v-model="payload.actual_start_date"
            placeholder="Выберите дату и время"
            enable-time-picker
            :format="DATE_TIME_FORMAT"
            value-format="yyyy-MM-dd HH:mm"
            :flow="['calendar', 'time']"
          />
          <div class="form-item__value-tags">
            <div
              class="form-item__value-tag"
              v-for="tag in dateTags"
              :key="tag.value"
              @click="payload.actual_start_date = moment().add(tag.value, 'hours').format('YYYY-MM-DD HH:mm')"
            >{{ tag.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">План. дата окончания</div>
        <div class="form-item__value form-item__value--column">
          <base-date-picker
            v-model="payload.planned_end_date"
            placeholder="Выберите дату и время"
            enable-time-picker
            :format="DATE_TIME_FORMAT"
            value-format="yyyy-MM-dd HH:mm"
            :flow="['calendar', 'time']"
            :min-date="payload.planned_start_date"
          />
          <div class="form-item__value-tags">
            <div
              class="form-item__value-tag"
              v-for="tag in dateTags.filter((tag, index) => index)"
              :key="tag.value"
              @click="payload.planned_end_date = moment().add(tag.value, 'hours').format('YYYY-MM-DD HH:mm')"
            >{{ tag.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Факт. дата окончания</div>
        <div class="form-item__value form-item__value--column">
          <base-date-picker
            v-model="payload.actual_end_date"
            placeholder="Выберите дату и время"
            enable-time-picker
            :format="DATE_TIME_FORMAT"
            value-format="yyyy-MM-dd HH:mm"
            :flow="['calendar', 'time']"
            :min-date="payload.actual_start_date"
          />
          <div class="form-item__value-tags">
            <div
              class="form-item__value-tag"
              v-for="tag in dateTags.filter((tag, index) => index)"
              :key="tag.value"
              @click="payload.actual_end_date = moment().add(tag.value, 'hours').format('YYYY-MM-DD HH:mm')"
            >{{ tag.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-item">
        <div class="form-item__name">Комментарий</div>
        <div class="form-item__value">
          <textarea
            v-model="payload.comment"
            class="base-textarea"
            type="text"
            placeholder="Напишите текст"/>
        </div>
      </div>
    </template>

    <template #footer>
      <div
        v-if="isCreateForm"
        @click="createShutdown()"
        class="button">
        Добавить
      </div>
      <div
        v-else
        @click="updateShutdown()"
        class="button">
        Сохранить
      </div>
    </template>
  </base-popup>
</template>

<script>
import {showToast, showAlert} from "@/utils/notification";
import {notBeforeStartDate} from "@/utils/helpers";
import BasePopup from "@/components/BasePopup.vue";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "ShutdownsForm",
  components: {BaseDatePicker, BasePopup},
  props: {
    issueId: {
      type: Number,
      required: true
    },
    shutdown: {
      type: Object,
      required: true
    },
    popupName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dateTags: [
        {value: 0, label: 'Сейчас'},
        {value: 2, label: '+2 ч'},
        {value: 4, label: '+4 ч'},
        {value: 8, label: '+8 ч'}
      ],
      payload: {
        planned_start_date: null,
        planned_end_date: null,
        actual_start_date: null,
        actual_end_date: null,
        comment: null
      }
    }
  },
  computed: {
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATEPICKER_DATE_TIME_FORMAT,
    isCreateForm() {
      return !Object.keys(this.shutdown).length
    },
    popupTitle() {
      return this.isCreateForm ? 'Новое отключение' : 'Редактирование отключения'
    }
  },
  mounted() {
    if (!this.isCreateForm) {
      const {planned_start_date, planned_end_date, actual_start_date, actual_end_date, comment} = this.shutdown;

      this.payload.planned_start_date = planned_start_date;
      this.payload.planned_end_date = planned_end_date;
      this.payload.actual_start_date = actual_start_date;
      this.payload.actual_end_date = actual_end_date;
      this.payload.comment = comment;
    }
  },
  methods: {
    moment,
    notBeforeStartDate,
    createShutdown() {
      const payload = {
        payload: this.payload,
        issue_id: this.issueId
      }
      this.$store.dispatch('shutdowns/createShutdown', payload)
        .then(() => {
          this.$modal.hide(this.popupName);
          showToast('Отключение добавлено', 'success');
          this.$store.dispatch('issueModal/getShutdownsList', this.issueId);
          this.$store.dispatch('issueModal/getIssueHistory', this.issueId);
        })
        .catch(error => {
          showAlert('Ошибка', error, 'error');
        })
    },
    updateShutdown() {
      this.$store.dispatch('shutdowns/updateShutdown', {
        issueId: this.issueId,
        shutdownId: this.shutdown.id,
        payload: this.payload
      })
        .then(() => {
          this.$modal.hide(this.popupName);
          showToast('Отключение обновлено', 'success');
          this.$store.dispatch('issueModal/getShutdownsList', this.issueId);
          this.$store.dispatch('issueModal/getIssueHistory', this.issueId);
        })
        .catch(error => {
          showAlert('Ошибка', error, 'error');
        })
    }
  }
}
</script>

<style scoped>

</style>
