<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Назначение бригады
      <span @click="$modal.hide('assign-brigade-form')"
            class="base-popup__close">
        <icon-close />
      </span>
    </div>
    <div class="assign-brigade-form">
      <div class="assign-brigade-form__resource">
        <div class="assign-brigade-form__resource-date">
          <div class="form-item">
            <div class="form-item__name">Плановая дата начала</div>
            <div class="form-item__value form-item__value--column">
              <base-date-picker
                v-model="payload.planned_start_date"
                enable-time-picker
                :minutes-increment="15"
                placeholder="Выберите дату"
                :format="DATE_TIME_FORMAT"
                value-format="yyyy-MM-dd HH:mm"
              />
              <div class="form-item__value-tags">
                <div
                  class="form-item__value-tag"
                  @click="setPlannedDate('planned_start_date')"
                >
                  Из заявки
                </div>
                <div
                  class="form-item__value-tag"
                  v-for="tag in dateTags"
                  :key="tag.value"
                  @click="payload.planned_start_date = moment().add(tag.value, 'hours').format('YYYY-MM-DD HH:mm')"
                >{{ tag.label }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item__name">Плановая дата окончания</div>
            <div class="form-item__value form-item__value--column">
              <base-date-picker
                v-model="payload.planned_end_date"
                enable-time-picker
                :minutes-increment="15"
                placeholder="Выберите дату"
                :format="DATE_TIME_FORMAT"
                value-format="yyyy-MM-dd HH:mm"
                :error="isDateInvalid"
                :min-date="payload.planned_start_date"
              />

              <span
                v-if="isDateInvalid"
                class="base-date-picker__error-txt">
                Плановая дата окончания должна быть позже плановой даты начала
              </span>
              <div class="form-item__value-tags">
                <div
                  class="form-item__value-tag"
                  @click="setPlannedDate('planned_end_date')"
                >
                  Из заявки
                </div>
                <div
                  class="form-item__value-tag"
                  v-for="tag in dateTags"
                  :key="tag.value"
                  @click="payload.planned_end_date = moment().add(tag.value, 'hours').format('YYYY-MM-DD HH:mm')"
                >{{ tag.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="assign-brigade-form__resources-list">
          <div class="assign-brigade-form__resources-caption">
            <span>Доступные ресурсы</span>
            <router-link
              to="/resources-load"
              target="_blank">
              Подробнее
            </router-link>
          </div>
          <div
            v-for="resource in resourcesList(departmentId)"
            :key="`resource_${resource.id}`"
            class="assign-brigade-form__resource-item">
            <label class="base-checkbox">
              <input
                @click="payload.brigade_id = resource.id"
                :checked="payload.brigade_id === resource.id"
                type="radio">
              <span class="assign-brigade-form__resources-name">{{resource.name}}</span>
            </label>
            <resource-load-indicator
              :resource-tasks-list="tasksList
                .filter(task => task.department.id === departmentId && task.brigade.id === resource.id)"
              :is-reversed-align="true"/>
          </div>
        </div>
      </div>
    </div>
    <div class="base-popup__footer base-popup__footer--flex">
      <div @click="submitForm()"
           class="button">
        Выбрать
      </div>
      <div @click="$modal.hide('assign-brigade-form')"
           class="button button--mode-outline">
        Отменить
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from "@/components/Icons/IconClose";
import ResourceLoadIndicator from "@/components/ServiceDesk/ResourcesLoad/ResourceLoadIndicator.vue";
import {mapGetters, mapState} from "vuex";
import {showAlert, showToast} from "@/utils/notification";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "AssignResourceForm",
  components: {BaseDatePicker, ResourceLoadIndicator, IconClose},
  props: {
    taskId: {
      type: Number,
      required: true
    },
    departmentId: {
      type: Number,
      required: true
    },
    oldPayload: {
      type: Object
    }
  },
  data() {
    return {
      payload: {
        brigade_id: null,
        planned_start_date: null,
        planned_end_date: null
      },
      dateTags: [
        {value: 0, label: 'Сейчас'},
        {value: 2, label: '+2 ч'},
        {value: 4, label: '+4 ч'},
        {value: 8, label: '+8 ч'}
      ],
      resourceId: null
    }
  },
  computed: {
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATEPICKER_DATE_TIME_FORMAT,
    ...mapState({
      tasksList: state => state.tasks.tasks,
      issue: state => state.issue.issue
    }),
    ...mapGetters({
      resourcesList: 'resources/getActiveResourcesByDepartmentId'
    }),
    isDateInvalid() {
      const startDate = new Date(this.payload.planned_start_date);
      const endDate = new Date(this.payload.planned_end_date);
      if (this.payload.planned_end_date) return startDate > endDate;
      else return false
    }
  },
  watch: {
    'payload.planned_start_date': function (value) {
      if (value && this.payload.planned_end_date) this.fetchTasks();

      if (value) {
        this.$store.dispatch('resources/getAvailableResourcesList', {
          check_date: moment(value).format('YYYY-MM-DD')
        })
      }
    },
    'payload.planned_end_date': function (value) {
      if (value && this.payload.planned_start_date) this.fetchTasks();
    }
  },
  mounted() {
    if (Object.keys(this.oldPayload).length) this.payload = this.oldPayload;
  },
  methods: {
    moment,
    fetchTasks() {
      if (this.payload.planned_start_date && this.payload.planned_end_date) {
        this.$store.dispatch('tasks/getAllTasks', {
          s_planned_start: this.payload.planned_start_date,
          e_planned_start: this.payload.planned_end_date
        })
      }
    },
    setPlannedDate(prop) {
      if (prop === 'planned_start_date') this.payload.planned_start_date = moment(this.issue.planned_start_at)
        .format('YYYY-MM-DD HH:mm');
      else this.payload.planned_end_date = moment(this.issue.planned_end_at).format('YYYY-MM-DD HH:mm');
    },
    submitForm() {
      if (!this.isDateInvalid) {
        this.$store.dispatch('task/assignBrigade', {
          task_id: this.taskId,
          payload: this.payload
        })
          .then(() => {
            this.$store.dispatch('taskModal/getTask', this.taskId);
            this.$modal.hide('assign-brigade-form');
            showToast('Ресурс назначен на задание', 'success');
            this.$store.dispatch('taskModal/getHistory', this.taskId);
          })
          .catch(error => showAlert('Ошибка', error, 'error'));
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use "@/assets/scss/helpers/index" as *;

.assign-brigade-form {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--border-main);

  &__resource {
    display: grid;
    grid-template-columns: 40% 60%;
  }

  &__resource-date {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-right: 20px;
    border-right: 1px solid var(--border-main);
  }

  &__resources-list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    padding-left: 20px;
    padding-right: 20px;

    max-height: 400px;
    overflow: auto;
  }

  &__resources-name {
    gap: 24px;
    cursor: pointer;
  }

  &__resource-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .base-checkbox {
      max-height: 100%;
    }
  }

  &__resources-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    & span {
      @include font-style(600, 16px, 24px, var(--text-primary));
    }

    & a {
      @include font-style(400, 14px, 16px, var(--text-secondary));
    }
  }
}
</style>
