import TechnicalInspectionStatisticService from "@/services/TechnicalInspectionStatisticService";

export const namespaced = true;

export const state = {
    resourcesStatistic: [],
    objectsStatistic: [],
    error: null,
    loading: false
}

export const mutations = {
    SET_RESOURCES_STATISTIC(state, statistic) {
        state.resourcesStatistic = statistic;
    },
    SET_OBJECTS_STATISTIC(state, statistic) {
        state.objectsStatistic = statistic;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading
    }
}

export const actions = {
    getResourcesStatistic({commit}, params) {
        commit("SET_LOADING", true);
        TechnicalInspectionStatisticService.fetchResourcesStatistic(params)
            .then(resourcesStatistic => commit("SET_RESOURCES_STATISTIC", resourcesStatistic))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    },
    getObjectsStatistic({commit}, params) {
        commit("SET_LOADING", true);
        TechnicalInspectionStatisticService.fetchObjectsStatistic(params)
            .then(objectsStatistic => commit("SET_OBJECTS_STATISTIC", objectsStatistic))
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false))
    }
}

export const getters = {
    getResourceStatisticWithCompletePercent: (state, getters) => {
        const completedTasksSum = getters.getResourceCompletedTasksCount;
        return state.resourcesStatistic.map(item => {
            const tasksCountPercent = item.completed_tasks_count * 100 / completedTasksSum;
            return {
                ...item,
                completed_tasks_count_percent: parseInt(tasksCountPercent.toFixed(0))
            }
        })
    },
    getFilteredResourceStatistic: state => {
        return state.resourcesStatistic.filter(item => item.completed_tasks_count > 0 || item.planned_tasks_count > 0);
    },
    getResourceCompletedTasksCount: state => {
        return state.resourcesStatistic
            .reduce((accum, stat) => accum + stat.completed_tasks_count, 0)
    },
    getResourcePlannedTasksCount: state => {
        return state.resourcesStatistic
            .reduce((accum, stat) => accum + stat.planned_tasks_count, 0)
    },
    getObjectsTasksCount: state => {
        return state.objectsStatistic.reduce((accum, stat) => accum + stat.tasks_count, 0);
    },
    getObjectsViolationsCount: state => {
        return state.objectsStatistic.reduce((accum, stat) => accum + stat.violations_count, 0);
    },
    getObjectsResolvedViolationsCount: state => {
        return state.objectsStatistic.reduce((accum, stat) => accum + stat.resolved_violations_count, 0);
    }
}
