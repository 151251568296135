import TechnicalInspectionObjectsService from "@/services/TechnicalInspectionObjectsService";

export const namespaced = true;

export const state = {
    object: {},
    objectsList: [],
    objectViolationsList: [],
    meta: null,
    page: 1,
    query: '',
    loading: false,
    error: null
}

export const mutations = {
    SET_OBJECT(state, object) {
        state.object = object;
    },
    SET_OBJECTS_LIST(state, objects) {
        state.objectsList = objects;
    },
    SET_OBJECT_VIOLATIONS_LIST(state, violationsList) {
        state.objectViolationsList = violationsList;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_QUERY(state, query) {
        state.query = query;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
}

export const actions = {
    getTechnicalInspectionObject({commit, dispatch}, objectId) {
        TechnicalInspectionObjectsService.fetchTechnicalInspectionObject(objectId)
            .then(object => {
                dispatch('questions/getQuestionsList', object._type.id, {root: true});
                dispatch('getTechnicalInspectionObjectViolationsList', {
                    objectId,
                    params: {
                        state: 'active'
                    }
                })
                commit("SET_OBJECT", object)
            })
            .catch(error => commit("SET_ERROR", error))
    },
    getTechnicalInspectionObjectsList({commit, state}) {
        commit("SET_LOADING", true);
        TechnicalInspectionObjectsService.fetchTechnicalInspectionObjectsList(
            {term: state.query.length ? state.query : null},
            state.page
        )
            .then(({objectsList, meta}) => {
                commit("SET_OBJECTS_LIST", objectsList);
                commit("SET_META", meta);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    getTechnicalInspectionObjectViolationsList({commit}, {objectId, params}) {
        TechnicalInspectionObjectsService.fetchTechnicalInspectionObjectViolationsList(objectId, params)
            .then(violationsList => commit("SET_OBJECT_VIOLATIONS_LIST", violationsList))
            .catch(error => commit("SET_ERROR", error))
    }
}
