<template>
  <div class="base-popup__wrapper">
    <div class="base-popup__header">
      Заказ транспорта
      <div
        v-if="transportOrder.isAccepted"
        @click="toggleEditFormMode()"
        class="base-popup__edit-icon">
        <icon-pencil />
      </div>
      <span @click="$modal.hide('transport-order-modal')"
            class="base-popup__close">
        <icon-close/>
      </span>
    </div>
    <div class="popup-form-group transport-order-popup">

      <div class="form-item form-item--row">
        <div class="form-item__name form-item__name--fixed-w">Задание</div>
        <div @click="handleOpenTaskModal(transportOrder.task.id)"
             class="form-item__value">
          <span class="task-id">#{{ transportOrder.task.id }}</span>
          {{ transportOrder.task.type.name }}
        </div>
      </div>

      <div class="form-item form-item--row">
        <div class="form-item__name form-item__name--fixed-w">Тип транспорта</div>
        <div class="form-item__value">{{ transportOrder.transportType.name }}</div>
      </div>

      <div class="form-item form-item--row">
        <div class="form-item__name form-item__name--fixed-w">Адрес</div>
        <div class="form-item__value">{{ transportOrder.fullAddress }}</div>
      </div>

      <div class="form-item form-item--row">
        <div class="form-item__name form-item__name--fixed-w">Комментарий</div>
        <div class="form-item__value">{{ transportOrder.comment }}</div>
      </div>

      <div v-if="transportOrder.isAccepted && !isEditFormMode">
        <div class="form-item form-item--border-top form-item--row">
          <div class="form-item__name form-item__name--fixed-w">Транспорт</div>
          <div class="form-item__value">
            {{ transportOrder.transport.name }} {{ transportOrder.transport.gov_number }}
          </div>
        </div>
      </div>
      <div v-if="transportOrder.isNew || isEditFormMode"
           class="form-item form-item--border-top">
        <div class="form-item__name">Транспорт</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.transport_id"
            :options="vehicles.filter(vehicle => vehicle.attributes.type === transportOrder.transportType.id)"
            :reduce="vehicle => vehicle.id"
            :get-option-label="(option) => option.attributes.label + ' ' + option.attributes.gov_number"
            placeholder="Выберите из списка"
            class="vue-select-input"
          />
        </div>
      </div>

      <div v-if="transportOrder.isAccepted && !isEditFormMode">
        <div class="form-item form-item--row">
          <div class="form-item__name form-item__name--fixed-w">Водитель</div>
          <div class="form-item__value">
            {{ transportOrder.brigade.name }}
          </div>
        </div>
      </div>
      <div v-if="transportOrder.isNew || isEditFormMode"
           class="form-item">
        <div class="form-item__name">Водитель</div>
        <div class="form-item__value">
          <v-select
            v-model="payload.brigade_id"
            :options="resourcesList(transportOrder.task.department_id)"
            :reduce="brigade => brigade.id"
            :get-option-label="(option) => option.name"
            placeholder="Выберите из списка"
            class="vue-select-input"
          />
        </div>
      </div>

      <div v-if="transportOrder.isAccepted && !isEditFormMode">
        <div class="form-item form-item--row">
          <div class="form-item__name form-item__name--fixed-w">Период работ</div>
          <div class="form-item__value">
            {{ transportOrder.plannedDatesCaption }}
          </div>
        </div>
      </div>
      <div
        v-if="transportOrder.isNew || isEditFormMode"
        class="popup-form-group transport-order-popup">
        <div class="form-item">
          <div class="form-item__name">Плановая дата начала работ</div>
          <div class="form-item__value form-item__value--column">
            <base-date-picker
              v-model="payload.planned_start_date"
              placeholder="Выберите дату и время"
              :format="DATE_TIME_FORMAT"
              enable-time-picker
              value-format="yyyy-MM-dd HH:mm"
              :flow="['calendar', 'time']"
            />
          </div>
        </div>

        <div class="form-item">
          <div class="form-item__name">Плановая дата окончания работ</div>
          <div class="form-item__value form-item__value--column">
            <base-date-picker
              v-model="payload.planned_end_date"
              placeholder="Выберите дату и время"
              :format="DATE_TIME_FORMAT"
              enable-time-picker
              value-format="yyyy-MM-dd HH:mm"
              :flow="['calendar', 'time']"
              :min-date="payload.planned_start_date"
            />
          </div>
        </div>
      </div>

    </div>
    <div v-if="transportOrder.isNew || (transportOrder.isAccepted && isEditFormMode)"
         class="base-popup__footer">
      <div @click="acceptTransportOrder()"
           class="button">
        Подтвердить
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {showToast, showAlert} from "@/utils/notification";
import IconClose from "@/components/Icons/IconClose";
import sideModalControls from "@/mixins/sideModalControls";
import IconPencil from "@/components/Icons/IconPencil.vue";
import TaskState from "@/models/serviceDesk/TaskState";
import moment from "moment";
import BaseDatePicker from "@/components/BaseDatePicker.vue";

export default {
  name: "TransportOrdersForm",
  components: {BaseDatePicker, IconPencil, IconClose},
  mixins: [sideModalControls],
  props: {
    transportOrder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      payload: {
        transport_id: null,
        brigade_id: null,
        planned_start_date: null,
        planned_end_date: null
      },
      isEditFormMode: false
    }
  },
  computed: {
    ...mapState({
      vehicles: state => state.autos.autos
    }),
    ...mapGetters({
      resourcesList: 'resources/getResourcesByDepartmentId'
    }),
    DATE_TIME_FORMAT: () => process.env.VUE_APP_BASE_DATEPICKER_DATE_TIME_FORMAT
  },
  watch: {
    'payload.planned_start_date': function (value) {
      this.$store.dispatch('resources/getAvailableResourcesList', {
        check_date: moment(value).format('YYYY-MM-DD')
      })
    }
  },
  mounted() {
    this.$store.dispatch('autos/getAllAutos', {state: 1});
    this.$store.dispatch('resources/getAvailableResourcesList', {
      check_date: moment(this.transportOrder?.plannedStartDate).format('YYYY-MM-DD')
    })

    if (this.transportOrder.isNew) {
      if (this.transportOrder.transport) this.payload.transport_id = this.transportOrder.transport.id;
      this.payload.planned_start_date = this.transportOrder.plannedStartDate;
      this.payload.planned_end_date = this.transportOrder.plannedEndDate;
    }
  },
  methods: {
    acceptTransportOrder() {
      this.$store.dispatch('transportOrders/acceptTransportOrder', {
        transportOrderId: this.transportOrder.id,
        payload: this.payload
      })
        .then(() => {
          this.$store.dispatch('transportOrders/getTransportOrdersList');
          this.$modal.hide('transport-order-modal');

          if (this.isEditFormMode) {
            showToast('Заказ транспорта обновлён', 'success');
            this.isEditFormMode = false;
          } else {
            showToast('Заказ транспорта подтверждён', 'success');
          }
        })
        .catch(error => showAlert('Ошибка', error))
    },
    toggleEditFormMode() {
      if (this.transportOrder.task.state.id === TaskState.NEW) {
        this.isEditFormMode = !this.isEditFormMode;

        if (this.isEditFormMode) {
          this.payload.transport_id = this.transportOrder.transport.id;
          this.payload.brigade_id = this.transportOrder.brigade.id;
          this.payload.planned_start_date = this.transportOrder.plannedStartDate;
          this.payload.planned_end_date = this.transportOrder.plannedEndDate;
        }
      } else showToast('Внесение изменений невозможно, задание не в статусе "Новое"');
    },
    handleOpenTaskModal(taskId) {
      this.$modal.hide('transport-order-modal');
      this.openTaskModal(taskId);
    },
    notBeforeStartDate(date) {
      let startDate = new Date(this.payload.planned_start_date);
      startDate.setDate(startDate.getDate() - 1);
      return date < startDate;
    }
  }
}
</script>

<style scoped>
.transport_order__info {
  color: var(--text-primary);
}

.transport_order__info span {
  color: var(--text-link);
}

.task-id {
  cursor: pointer;
  margin-right: 4px;
  color: var(--text-link);
}

.transport-order-popup {
  gap: 12px;
}
</style>
