<template>
  <div class="page-wrapper">
    <base-panel
      title="Категории"
      :is-add-button-visible="true"
      add-button-name="Добавить категорию"
      @add-button-clicked="showPopup()"
    />

    <base-functional-line
      search-placeholder="Искать по наименованию"
      @search-change="event => this.query = event.target.value"
    />

    <categories-list
      v-if="categoriesList.length"
      :categories-list="categoriesList"
      @edit-category="showPopup"
      @delete-category="deleteCategory" />

    <base-content-loaders v-if="categoriesLoading && !categoriesList.length" />

    <base-no-data v-if="!categoriesLoading && !categoriesList.length"/>

    <base-paginate
      v-if="categoriesMeta && categoriesMeta.last_page > 1"
      :meta="categoriesMeta"
      commit="serviceDeskCategories/SET_PAGE"
      action="serviceDeskCategories/getCategories" />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import CategoriesList from "@/components/ServiceDesk/admin/CategoriesList.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import {mapState} from "vuex";
import {showAlert, showToast, showConfirmationModal} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";
import CategoryForm from "@/components/ServiceDesk/admin/CategoryForm.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import BaseNoData from "@/components/BaseNoData.vue";

export default {
  name: "CategoriesListPage",
  components: {BaseNoData, BaseFunctionalLine, BaseContentLoaders, BasePaginate, CategoriesList, BasePanel},
  computed: {
    ...mapState({
      categoriesList: state => state.serviceDeskCategories.categories,
      categoryGroups: state => state.serviceDeskCategories.categoryGroups,
      categoriesMeta: state => state.serviceDeskCategories.meta,
      categoriesLoading: state => state.serviceDeskCategories.categoriesLoading
    }),
    query: {
      get() {
        return this.$store.state.serviceDeskCategories.query;
      },
      set(value) {
        this.$store.commit('serviceDeskCategories/SET_QUERY', value);
      }
    }
  },
  watch: {
    query: function() {
      this.$store.commit('serviceDeskCategories/SET_PAGE', 1);
      this.$store.dispatch('serviceDeskCategories/getCategories');
    }
  },
  mounted() {
    this.$store.commit('serviceDeskCategories/SET_PAGE', 1);
    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
    this.$store.dispatch('serviceDeskCategories/getCategories');
  },
  methods: {
    showPopup(category = {}) {
      this.$modal.show(
        CategoryForm,
        {
          'category': category,
          'categoryGroupsList': this.categoryGroups,
          'createCategory': this.createCategory,
          'updateCategory': this.updateCategory
        },
        {
          name: 'category-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    createCategory(payload) {
      this.$store.dispatch('serviceDeskCategories/createCategory', payload)
          .then(() => {
            showToast('Категория добавлена', 'success');
            this.$store.dispatch('serviceDeskCategories/getCategories');
            this.$modal.hide('category-form');
          })
          .catch(error => showAlert('Ошибка', error))
    },
    updateCategory(categoryId, payload) {
      this.$store.dispatch('serviceDeskCategories/updateCategory', {
        id: categoryId,
        payload: payload
      })
          .then(() => {
            showToast('Категория обновлена', 'success');
            this.$store.dispatch('serviceDeskCategories/getCategories');
            this.$modal.hide('category-form');
          })
          .catch(error => showAlert('Ошибка', error, 'error'))
    },
    deleteCategory(categoryId) {
      showConfirmationModal('Вы уверены, что хотите удалить категорию?')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('serviceDeskCategories/deleteCategory', categoryId)
              .then(() => {
                this.$store.commit('serviceDeskCategories/DELETE_CATEGORY', categoryId);
                showToast('Категория удалена', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
