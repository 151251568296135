import File from "@/models/serviceDesk/File";

export default class TaskViolation {
    constructor(params) {
        this.id = params.id;
        this.violation = params.violation;
        this.description = params.description;
        this.createdDate = params.created_date;
        this.finishedDate = params.finished_date;
        this.canceledDate = params.canceled_date;
        this.fields = params.fields;
        this.attachments = params.attachments.map(item => new File(item.id, {
            name: item.description,
            path: item.url
        }));
        this.task = params.task || {};
    }
}
