<template>
  <div class="page-wrapper">
    <base-panel
      title="Средства малой механизации"
      :is-add-button-visible="true"
      add-button-name="Добавить СММ"
      @add-button-clicked="showPopup()"
    />

    <base-functional-line
      search-placeholder="Искать по наименованию"
      @search-change="event => this.query = event.target.value"
    />

    <mechanical-equipments-list
      v-if="mechanicalEquipmentsList.length"
      :mechanical-equipments-list="mechanicalEquipmentsList"
      @edit-mechanical-equipment="showPopup"
      @delete-mechanical-equipment="deleteMechanicalEquipment"
    />

    <base-content-loaders v-else-if="loading"/>

    <base-no-data v-if="!loading && !mechanicalEquipmentsList.length"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      commit="mechanicalEquipments/SET_PAGE"
      action="mechanicalEquipments/getMechanicalEquipments"
    />
  </div>
</template>

<script>
import MechanicalEquipmentsList from "@/components/ServiceDesk/admin/MechanicalEquipmentsList";
import BasePanel from "@/components/BasePanel.vue";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import {mapState} from "vuex";
import BasePaginate from "@/components/BasePaginate.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";
import MechanicalEquipmentForm from "@/components/ServiceDesk/admin/MechanicalEquipmentForm.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import BaseNoData from "@/components/BaseNoData.vue";

export default {
  name: "MechanicalEquipmentsListPage",
  components: {BaseNoData, BaseFunctionalLine, BasePaginate, BaseContentLoaders, BasePanel, MechanicalEquipmentsList},
  computed: {
    ...mapState({
      mechanicalEquipmentsList: state => state.mechanicalEquipments.mechanicalEquipments,
      meta: state => state.mechanicalEquipments.meta,
      loading: state => state.mechanicalEquipments.loading,
      fuelTypes: state => state.mechanicalEquipments.fuelTypes,
      oilTypes: state => state.mechanicalEquipments.oilTypes
    }),
    query: {
      get() {
        return this.$store.state.mechanicalEquipments.query;
      },
      set(value) {
        this.$store.commit('mechanicalEquipments/SET_QUERY', value);
      }
    }
  },
  watch: {
    query: function () {
      this.$store.commit('mechanicalEquipments/SET_PAGE', 1);
      this.$store.dispatch('mechanicalEquipments/getMechanicalEquipments');
    }
  },
  mounted() {
    this.$store.commit('mechanicalEquipments/SET_PAGE', 1);
    this.$store.dispatch('mechanicalEquipments/getMechanicalEquipments');

    this.$store.dispatch('mechanicalEquipments/getFuelTypes');
    this.$store.dispatch('mechanicalEquipments/getOilTypes');
  },
  methods: {
    showPopup(mechanicalEquipment = {}) {
      this.$modal.show(
        MechanicalEquipmentForm,
        {
          'mechanicalEquipment': mechanicalEquipment,
          'createMechanicalEquipment': this.createMechanicalEquipment,
          'updateMechanicalEquipment': this.updateMechanicalEquipment,
          'fuelTypes': this.fuelTypes,
          'oilTypes': this.oilTypes
        },
        {
          name: 'mechanical-equipment-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460)
        })
    },
    createMechanicalEquipment(payload) {
      this.$store.dispatch('mechanicalEquipments/createMechanicalEquipment', payload)
        .then(() => {
          this.$store.dispatch('mechanicalEquipments/getMechanicalEquipments');
          this.$modal.hide('mechanical-equipment-form');
          showToast('Средство малой механизации добавлено', 'success');
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    },
    updateMechanicalEquipment(equipmentId, payload) {
      this.$store.dispatch('mechanicalEquipments/updateMechanicalEquipment', {
        id: equipmentId,
        payload: payload
      })
        .then(() => {
          this.$store.dispatch('mechanicalEquipments/getMechanicalEquipments');
          this.$modal.hide('mechanical-equipment-form');
          showToast('Средство малой механизации изменено', 'success');
        })
        .catch(error => showAlert('Ошибка', error, 'error'));
    },
    deleteMechanicalEquipment(equipmentId) {
      showConfirmationModal('Вы уверены, что хотите удалить СММ?')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('mechanicalEquipments/deleteMechanicalEquipment', equipmentId)
              .then(() => {
                showToast('Средство малой механизации удалено', 'success');
                this.$store.commit('mechanicalEquipments/DELETE_MECHANICAL_EQUIPMENT', equipmentId);
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
