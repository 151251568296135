<template>
  <div class="page-wrapper">
    <base-panel
      title="Типы заданий"
      :is-add-button-visible="true"
      add-button-name="Добавить тип задания"
      @add-button-clicked="showPopup()"
    />

    <base-functional-line
      search-placeholder="Искать по наименованию"
      @search-change="event => this.query = event.target.value"
    />

    <task-types-list
      v-if="taskTypesList.length"
      :task-types-list="taskTypesList"
      @edit-task-type="showPopup"
      @delete-task-type="deleteTaskType"/>

    <base-content-loaders v-else-if="loading"/>

    <base-no-data v-if="!loading && !taskTypesList.length"/>

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      commit="task_types/SET_PAGE"
      action="task_types/getTaskTypes" />
  </div>
</template>

<script>
import TaskTypesList from "@/components/ServiceDesk/admin/TaskTypesList";
import BasePanel from "@/components/BasePanel.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import {showAlert, showConfirmationModal, showToast} from "@/utils/notification";
import {modalAdaptiveWidth} from "@/utils/helpers";
import TaskTypesForm from "@/components/ServiceDesk/admin/TaskTypesForm.vue";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";
import BaseNoData from "@/components/BaseNoData.vue";

export default {
  name: "TaskTypesListPage",
  components: {BaseNoData, BaseFunctionalLine, BasePaginate, BaseContentLoaders, BasePanel, TaskTypesList},
  computed: {
    ...mapState({
      taskTypesList: state => state.task_types.task_types,
      meta: state => state.task_types.meta,
      loading: state => state.task_types.loading,
      taskTypeFeaturesList: state => state.task_types.taskTypesFeatures,
      categoryGroupsList: state => state.serviceDeskCategories.categoryGroups
    }),
    query: {
      get() {
        return this.$store.state.task_types.query
      },
      set(value) {
        this.$store.commit('task_types/SET_QUERY', value);
      }
    }
  },
  watch: {
    query: function () {
      this.$store.commit('task_types/SET_PAGE', 1);
      this.$store.dispatch('task_types/getTaskTypes');
    }
  },
  mounted() {
    this.$store.commit('task_types/SET_PAGE', 1);
    this.$store.dispatch('task_types/getTaskTypes');

    this.$store.dispatch('serviceDeskCategories/getCategoryGroups');
    this.$store.dispatch('task_types/getTaskTypesFeatures');
  },
  methods: {
    showPopup(taskType = {}) {
      this.$modal.show(
        TaskTypesForm,
        {
          'taskType': taskType,
          'categoryGroupsList': this.categoryGroupsList,
          'featuresList': this.taskTypeFeaturesList,
          'createTaskType': this.createTaskType,
          'updateTaskType': this.updateTaskType
        },
        {
          name: 'task-type-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(460),
          scrollable: true
        })
    },
    createTaskType(payload) {
      this.$store.dispatch('task_types/createTaskType', payload)
        .then(() => {
          showToast('Тип задания добавлен', 'success');
          this.$modal.hide('task-type-form');
          this.$store.dispatch('task_types/getTaskTypes');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateTaskType(taskTypeId, payload) {
      this.$store.dispatch('task_types/updateTaskType', {
        taskTypeId, payload
      })
        .then(() => {
          showToast('Тип задания обновлён', 'success');
          this.$modal.hide('task-type-form');
          this.$store.dispatch('task_types/getTaskTypes');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteTaskType(taskTypeId) {
      showConfirmationModal('Вы уверены, что хотите удалить тип задания?')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('task_types/deleteTaskType', taskTypeId)
              .then(() => {
                this.$store.commit('task_types/DELETE_TASK_TYPE', taskTypeId);
                showToast('Тип задания удалён', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
