import DepartmnetsService from "@/services/DepartmnetsService";
import {getError} from "@/utils/helpers";
export const namespaced = true;
export const state = {
    departments: [],
    department: {},
    staff: [],
    resourcesList: [],
    meta: {},
    links: null,
    error: null,
    loading: false,
    staffLoading: false,
    page: 1,
    departmentInfoLoading: false,
    query: ''
}
export const mutations = {
    SET_DEPARTMENTS(state, departments) {
        state.departments = departments;
    },
    TOGGLE_DEPARTMENT_STATUS(state, departmentId) {
        const index = state.departments.findIndex(department => department.id === departmentId);
        if (state.departments[index].activity) state.departments[index].activity = 0
        else state.departments[index].activity = 1
    },
    SET_DEPARTMENT(state, department) {
        state.department = department;
    },
    SET_STAFF(state, staff) {
        state.staff = staff;
    },
    SET_RESOURCES_LIST(state, resourcesList) {
        state.resourcesList = resourcesList;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_STAFF_LOADING(state, loading) {
        state.staffLoading = loading;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    SET_DEPARTMENT_INFO_LOADING(state, loading) {
        state.departmentInfoLoading = loading;
    },
    DELETE_EMPLOYEE(state, employeeId) {
        const index = state.staff.findIndex(employee => employee.id === employeeId);
        state.staff.splice(index, 1);
    },
    DELETE_RESOURCE(state, resourceId) {
        const index = state.resourcesList.findIndex(resource => resource.id === resourceId);
        state.resourcesList.splice(index, 1);
    },
    SET_QUERY(state, query) {
        state.query = query;
    }
}

export const actions = {
    getAllDepartments({commit}) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
            DepartmnetsService.getAllDepartments()
                .then(departments => {
                    commit("SET_DEPARTMENTS", departments);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_LOADING", false));
        })
    },
    getDepartments({commit, state}) {
        commit("SET_LOADING", true);
        DepartmnetsService.getDepartments(state.page, state.query)
            .then(({departments, meta, links}) => {
                commit("SET_DEPARTMENTS", departments);
                commit("SET_META", meta);
                commit("SET_LINKS", links);
            })
            .catch(error => commit("SET_ERROR", error))
            .finally(() => commit("SET_LOADING", false));
    },
    async getDepartmentInfo({commit, dispatch}, departmentId) {
        commit("SET_DEPARTMENT_INFO_LOADING", true);

        await Promise.all([
            dispatch('getDepartment', departmentId),
            dispatch('getAllDepartmentResources', departmentId),
            dispatch('getDepartmentStaffList', departmentId)
        ])
            .finally(() => commit("SET_DEPARTMENT_INFO_LOADING", false));
    },
    getDepartment({commit}, department_id) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.fetchDepartment(department_id)
                .then(department => {
                    commit("SET_DEPARTMENT", department);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    createDepartment({commit}, payload) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.createDepartment(payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    updateDepartment({commit}, {department_id, payload}) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.updateDepartment({department_id, payload})
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                });
        })
    },
    activateDepartment({commit}, department_id) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.activateDepartment(department_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    deactivateDepartment({commit}, department_id) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.deactivateDepartment(department_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject(error);
                })
        })
    },
    getAllDepartmentResources({commit}, department_id) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.fetchAllDepartmentBrigades(department_id)
                .then(resources => {
                    commit("SET_RESOURCES_LIST", resources);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                });
        })
    },
    getDepartmentStaffList({commit}, department_id) {
        commit("SET_STAFF_LOADING", true);
        return new Promise((resolve, reject) => {
            DepartmnetsService.fetchDepartmentStaffList(department_id)
                .then(staff => {
                    commit("SET_STAFF", staff);
                    resolve();
                })
                .catch(error => {
                    commit("SET_ERROR", error);
                    reject();
                })
                .finally(() => commit("SET_STAFF_LOADING", false));
        })
    },
    addEmployee({commit}, {department_id, payload}) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.addEmployeeToDepartment(department_id, payload)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    },
    removeEmployee({commit}, {department_id, employee_id}) {
        return new Promise((resolve, reject) => {
            DepartmnetsService.removeEmployeeFromDepartment(department_id, employee_id)
                .then(() => resolve())
                .catch(error => {
                    commit("SET_ERROR", getError(error));
                    reject(getError(error));
                })
        })
    }
}

export const getters = {
    getDepartments: state => {
        return state.departments;
    },
    getDepartmentById: state => id => {
        return state.departments.find(department => department.id === parseInt(id));
    },
    getDepartment: state => {
        return state.department;
    },
    getDepartmentName: state => id => {
        const department = state.departments.find(department => department.id === id);
        if (department) return department.name;
        else return '-'
    },
    getStaff: state => {
        return state.staff;
    }
}
