<template>
  <div class="page-wrapper">
    <base-panel
      title="Ресурсы"
      :is-add-button-visible="true"
      add-button-name="Добавить ресурс"
      @add-button-clicked="showPopup()"
    />

    <base-functional-line @search-change="event => this.query = event.target.value"/>

    <resources-list
      :resources-list="resourcesList"
      @update-resource="showPopup"
      @delete-resource="deleteResource"
      @update-sort-by="value => sortBy = value"
      @update-sort-order="value => sortOrder = value"
    />

    <base-content-loaders v-if="loading && !resourcesList.length" />

    <base-no-data v-if="!loading && !resourcesList.length" />

    <base-paginate
      v-if="meta && meta.last_page > 1"
      :meta="meta"
      action="resources/getResourcesList"
      commit="resources/SET_PAGE"
    />
  </div>
</template>

<script>
import BasePanel from "@/components/BasePanel.vue";
import ResourcesList from "@/components/ServiceDesk/admin/Resources/ResourcesList.vue";
import {mapState} from "vuex";
import BaseContentLoaders from "@/components/BaseContentLoaders.vue";
import BaseNoData from "@/components/BaseNoData.vue";
import BasePaginate from "@/components/BasePaginate.vue";
import ResourcesForm from "@/components/ServiceDesk/admin/Resources/ResourcesForm.vue";
import {modalAdaptiveWidth} from "@/utils/helpers";
import {showToast, showAlert, showConfirmationModal} from "@/utils/notification";
import BaseFunctionalLine from "@/components/BaseFunctionalLine.vue";

export default {
  name: "ResourcesListPage",
  components: {BaseFunctionalLine, BasePaginate, BaseNoData, BaseContentLoaders, ResourcesList, BasePanel},
  computed: {
    ...mapState({
      resourcesList: state => state.resources.resourcesList,
      loading: state => state.resources.loading,
      meta: state => state.resources.meta
    }),
    query: {
      get() {
        return this.$store.state.resources.query
      },
      set(value) {
        if (value.length > 0) this.$store.commit('resources/SET_PAGE', 1);
        this.$store.commit('resources/SET_QUERY', value)
      }
    }
  },
  watch: {
    query: function () {
      this.$store.dispatch('resources/getResourcesList')
    }
  },
  mounted() {
    this.$store.dispatch('user/getStructuralUniUsersList');
    this.$store.dispatch('autos/getAllAutos');
    this.$store.dispatch('departments/getAllDepartments');
    this.$store.dispatch('structuralUnits/getUserStructuralUnitsList');
    this.$store.dispatch('resources/getResourcesList');
    this.$store.dispatch('warehouses/getWarehousesList');
  },
  beforeUnmount() {
    this.query = '';
  },
  methods: {
    showPopup(resource = {}) {
      this.$modal.show(
        ResourcesForm,
        {
          'resource': resource,
          'createResource': this.createResource,
          'updateResource': this.updateResource,
          'popupName': 'resources-form'
        },
        {
          name: 'resources-form',
          classes: 'base-popup',
          height: 'auto',
          width: modalAdaptiveWidth(920),
          scrollable: true
        }
      )
    },
    createResource(payload) {
      this.$store.dispatch('resources/createResource', payload)
        .then(() => {
          this.$store.dispatch('resources/getResourcesList');
          this.$modal.hide('resources-form');
          showToast('Ресурс добавлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    updateResource(resourceId, payload) {
      payload.activity = payload.activity ? 1 : 0;
      this.$store.dispatch('resources/updateResource', {
        resourceId, payload
      })
        .then(() => {
          this.$store.dispatch('resources/getResourcesList');
          this.$modal.hide('resources-form');
          showToast('Ресурс обновлен', 'success');
        })
        .catch(error => showAlert('Ошибка', error))
    },
    deleteResource(resourceId) {
      showConfirmationModal('Вы уверены, что хотите удалить ресурс? Это действие невозвратно.')
        .then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('resources/deleteResource', resourceId)
              .then(() => {
                this.$store.commit('resources/DELETE_RESOURCE', resourceId);
                showToast('Ресурс удален', 'success');
              })
              .catch(error => showAlert('Ошибка', error))
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
