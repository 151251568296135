<template>
  <div class="base-table base-table--issues">
    <div class="base-table__container">
      <div class="base-table__header base-table__header--active-violations">
        <div class="base-table__header-cell">Тип нарушения</div>
        <div class="base-table__header-cell">Дата обнаружения</div>
      </div>

      <div v-for="violation in violationsList"
           :key="violation.id"
           class="base-table__row base-table__header--active-violations">
        <div class="base-table__column">
          <div class="base-table__text">{{ violation.violation.name }}</div>
        </div>
        <div class="base-table__column base-table__column--gap-8">
          <div class="base-table__text">
            {{ violation.createdDate }}
          </div>
          <div
            @click="openTaskModal(violation.task.id)"
            class="base-table__text base-table__text--link"
          >#{{ violation.task.id }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ActiveViolationsList",
  props: {
    violationsList: {
      type: Array,
      required: true
    }
  },
  methods: {
    openTaskModal(taskId) {
      this.$store.dispatch('baseSideModal/openModal', {
        modalContentType: 'technicalInspectionTask',
        entityId: taskId
      });
    }
  }
})
</script>

<style scoped>
.base-table__header--active-violations {
  grid-template-columns: 1fr 1fr;
}
</style>
